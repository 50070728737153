import cx from "classnames"

export const selectFieldCustomStyle = (showErrorBorder = false) => {
  return {
    container: (provided, _) => ({
      ...provided,
      fontFamily: "NeuzeitGro, georgia",
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: 0,
      border: `2px solid ${showErrorBorder ? "var(--error-red)" : "var(--grey)"}`,
      borderColor: showErrorBorder ? "var(--error-red)" : "var(--grey)",
      fontSize: "16px",
      color: state.isFocused ? "var(--purple)" : "",
      boxShadow: "none",
      "&:hover": {
        border: "2px solid var(--grey)",
      },
    }),
    singleValue: (provided, _) => ({
      ...provided,
      color: "#000",
    }),
    menu: (provided, state) => ({
      ...provided,
      borderColor: state.Selected ? "var(--purple)" : "",
      borderRadius: 0,
      borderLeft: "2px solid var(--grey)",
      borderRight: "2px solid var(--grey)",
      borderBottom: "2px solid var(--grey)",
      fontSize: "16px",
      marginBottom: 0,
      marginTop: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: "2px solid var(--lightest-grey)",
      backgroundColor: state.isSelected ? "var(--purple)" : "",
      "&:hover": {
        background: "var(--purple)",
        color: "var(--white)",
      },
    }),
    valueContainer: (provided, _) => ({
      ...provided,
      alignContent: "center",
      height: "42px",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = "opacity 300ms"

      return { ...provided, opacity, transition }
    },
  }
}
