import React, { useState } from "react"
import axios from "axios"
import cx from "classnames"
import { Field, ErrorMessage } from "formik"
import debounce from "debounce-promise"
import AsyncSelectField from "../../../../shared/components/AsyncSelectField"
import buttonStyles from "../../../../shared/styles/adminButtons.module.scss"
import formStyles from "../../../../shared/styles/form.module.scss"
import styles from "../styles/VendorCreditsForm.module.scss"

const ContributorForm = ({ form, push, remove }) => {
  const searchContributors = async (query) => {
    return await axios
      .get("/api/v1/contributors/autocomplete", { params: { query } })
      .then((response) => response.data)
      .catch((error) => {
        console.log(error)
        return []
      })
  }

  const setValueAndSplit = (fieldName, selectedValue) => {
    console.log(fieldName, selectedValue)
    form.setFieldValue(fieldName, selectedValue)
    const shareInfo = selectedValue.label.match(/\(share\:\s+([\d\.]+)/)
    const share = shareInfo !== null ? parseFloat(shareInfo[1]) : ""

    if (typeof share === "number") {
      const shareFieldName = fieldName.replace("contributor_id", "share")
      form.setFieldValue(shareFieldName, share)
    }
  }
  return (
    <div>
      <div>
        <span>
          You can{" "}
          <a href="/manage/contributors/new" target="_blank">
            create a New Contributor
          </a>{" "}
          here
        </span>
      </div>
      {form.values.song_version_contributors_attributes.length > 0 &&
        form.values.song_version_contributors_attributes.map((contributorAttr, index) => (
          <div key={index} className={styles.form} data-cy={`contributor-form-${index}`}>
            <div className={styles.cornerLabel}>
              {contributorAttr.id === "" && (
                <button
                  type="button"
                  className={styles.remove}
                  onClick={() => remove(index)}
                  data-cy={`remove-contributor-${index}`}
                >
                  X
                </button>
              )}
            </div>
            <Field
              type="hidden"
              name={`song_version_contributors_attributes.${index}.id`}
              value={contributorAttr.id}
              onChange={form.setFieldValue}
            />
            <AsyncSelectField
              admin={true}
              label="Contributor*"
              fieldName={`song_version_contributors_attributes.${index}.contributor_id`}
              value={contributorAttr.contributor_id}
              loadOptions={debounce(searchContributors, 400)}
              onChange={setValueAndSplit}
              onBlur={form.setFieldTouched}
              placeholder="Search..."
              canCreateOption={false} //for now, we won't allow this, too many complicated DB relations
            />

            <div className="field-row">
              <label className={cx(formStyles.label, formStyles.admin)}>IPI Number*</label>
              <Field
                type="text"
                value={contributorAttr.contributor_id.ipi_number}
                name={`song_version_contributors_attributes.${index}.ipi_number`}
              />
              <ErrorMessage
                name={`song_version_contributors_attributes.${index}.ipi_number`}
                className="error-msg"
                component="div"
              />
            </div>

            <div className="field-row" key={index}>
              <label className={cx(formStyles.label, formStyles.admin)}>Split*</label>
              <Field
                type="number"
                name={`song_version_contributors_attributes.${index}.share`}
                step="0.01"
              />
              <ErrorMessage
                name={`song_version_contributors_attributes.${index}.share`}
                className="error-msg"
                component="div"
              />
              {contributorAttr.id !== "" && (
                <label style={{ display: "block", marginTop: "20px" }}>
                  <Field
                    name={`song_version_contributors_attributes.${index}._destroy`}
                    type="checkbox"
                    checked={contributorAttr._destroy}
                  />
                  Delete
                </label>
              )}
            </div>
          </div>
        ))}
      <button
        type="button"
        className={buttonStyles.button}
        data-cy="add-contributor"
        onClick={() => push({ id: "", contributor_id: "", share: "", _destroy: false })}
      >
        Add Contributor
      </button>
    </div>
  )
}

export default ContributorForm
