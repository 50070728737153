import React from "react"
import AsyncSelect from "react-select/async"
import AsyncCreatableSelect from "react-select/async-creatable"
import { ErrorMessage } from "formik"
import { formatId, formatAriaLabel } from "../utils/form"
import { selectFieldCustomStyle } from "../styles/customStyles"
import cx from "classnames"
import formStyles from "../styles/form.module.scss"

const AsyncSelectField = (props) => {
  const {
    fieldName,
    label,
    value,
    isDisabled,
    isClearable,
    isMulti,
    closeMenuOnSelect,
    loadOptions,
    placeholder,
    defaultOptions,
    loadOnFocus,
    canCreateOption,
    classNamePrefix,
    showErrorBorder,
    admin,
  } = props

  const handleChange = (selectedValue) => {
    props.onChange(fieldName, selectedValue)
  }

  const handleBlur = () => {
    props.onBlur(fieldName, true)
  }

  const handleOnFocus = () => {
    loadOnFocus ? props.loadOptions() : () => false
  }

  const selectProps = {
    placeholder,
    isMulti,
    value,
    placeholder,
    loadOptions,
    defaultOptions,
    closeMenuOnSelect,
    isDisabled,
    isClearable,
    classNamePrefix,
    id: formatId(fieldName),
    onChange: handleChange,
    onBlur: handleBlur,
    onFocus: handleOnFocus,
    styles: admin ? {} : selectFieldCustomStyle(showErrorBorder),
  }

  return (
    <div className={formStyles.fieldRow}>
      <label htmlFor={fieldName} className={cx(formStyles.label, { [formStyles.admin]: admin })}>
        {label}
      </label>
      {canCreateOption ? (
        <AsyncCreatableSelect {...selectProps} aria-label={formatAriaLabel(fieldName)} />
      ) : (
        <AsyncSelect {...selectProps} />
      )}
      <ErrorMessage name={fieldName} className="error-msg" component="div" />
    </div>
  )
}

AsyncSelectField.defaultProps = {
  isMulti: false,
  closeMenuOnSelect: true,
  defaultOptions: false,
  canCreateOption: false,
  placeholder: "Select...",
  showLabel: true,
  isDisabled: false,
  isClearable: false,
  className: "react-select-container",
  classNamePrefix: "react-select",
  admin: false,
  showErrorBorder: false,
}

export default AsyncSelectField
