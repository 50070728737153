import React from "react"
import axios from "axios"
import cx from "classnames"
import { Field, ErrorMessage } from "formik"
import debounce from "debounce-promise"
import AsyncSelectField from "../../../../shared/components/AsyncSelectField"
import SelectField from "../../../../shared/components/SelectField"
import styles from "../styles/VendorCreditsForm.module.scss"
import buttonStyles from "../../../../shared/styles/adminButtons.module.scss"
import formStyles from "../../../../shared/styles/form.module.scss"

const PublisherForm = ({ form, push, remove }) => {
  const contributorOptions = form.values.song_version_contributors_attributes.map((contributor) =>
    Object({ label: contributor.contributor_id.label, value: contributor.contributor_id.value })
  )

  const searchPublishers = async (query) => {
    return await axios
      .get("/api/v1/publishers/autocomplete", { params: { query } })
      .then((response) => response.data)
      .catch((error) => {
        console.log(error)
        return []
      })
  }
  const setValueAndSplit = (fieldName, selectedValue) => {
    form.setFieldValue(fieldName, selectedValue)
    const shareInfo = selectedValue.label.match(/\(share\:\s+([\d\.]+)/)
    const share = shareInfo !== null ? parseFloat(shareInfo[1]) : ""

    if (typeof share === "number") {
      const shareFieldName = fieldName.replace("publisher_id", "share")
      form.setFieldValue(shareFieldName, share)
    }
  }
  return (
    <div>
      <div>
        <span>
          You can{" "}
          <a href="/manage/publishers/new" target="_blank">
            create a New Publisher
          </a>{" "}
          here
        </span>
      </div>
      {form.values.song_version_publishers_attributes.length > 0 &&
        form.values.song_version_publishers_attributes.map((publisherAttr, index) => (
          <div key={index} className={styles.form} data-cy={`publisher-form-${index}`}>
            <div className={styles.cornerLabel}>
              {publisherAttr.id === "" && (
                <button
                  type="button"
                  className={styles.remove}
                  onClick={() => remove(index)}
                  data-cy={`remove-publisher-${index}`}
                >
                  X
                </button>
              )}
            </div>
            <Field
              type="hidden"
              name={`song_version_publishers_attributes.${index}.id`}
              value={publisherAttr.id}
              onChange={form.setFieldValue}
            />

            <SelectField
              admin
              placeholder="Select from the above Contributors..."
              label="Contributor*"
              fieldName={`song_version_publishers_attributes.${index}.contributor_id`}
              options={[...contributorOptions, { label: "PLACEHOLDER CONTRIBUTOR", value: 363 }]}
              value={publisherAttr.contributor_id}
              onChange={setValueAndSplit}
              onBlur={form.setFieldTouched}
            />
            <AsyncSelectField
              admin={true}
              label="Publisher*"
              fieldName={`song_version_publishers_attributes.${index}.publisher_id`}
              value={publisherAttr.publisher_id}
              loadOptions={debounce(searchPublishers, 400)}
              onChange={setValueAndSplit}
              onBlur={form.setFieldTouched}
              placeholder="Search..."
              canCreateOption={false} //for now, we won't allow this, too many complicated DB relations
            />

            <div className="field-row">
              <label className={cx(formStyles.label, formStyles.admin)}>IPI Number*</label>
              <Field
                type="text"
                value={publisherAttr.publisher_id.ipi_number}
                name={`song_version_publishers_attributes.${index}.ipi_number`}
              />
              <ErrorMessage
                name={`song_version_publishers_attributes.${index}.ipi_number`}
                className="error-msg"
                component="div"
              />
            </div>

            <div className="field-row" key={index}>
              <label className={cx(formStyles.label, formStyles.admin)}>Split*</label>
              <Field
                type="number"
                name={`song_version_publishers_attributes.${index}.share`}
                step="0.01"
              />
              <ErrorMessage
                name={`song_version_publishers_attributes.${index}.share`}
                className="error-msg"
                component="div"
              />
              {publisherAttr.id !== "" && (
                <label style={{ display: "block", marginTop: "20px" }}>
                  <Field
                    name={`song_version_publishers_attributes.${index}._destroy`}
                    type="checkbox"
                    checked={publisherAttr._destroy}
                  />
                  Delete
                </label>
              )}
            </div>
          </div>
        ))}
      <button
        type="button"
        className={buttonStyles.button}
        data-cy="add-publisher"
        onClick={() =>
          push({ id: "", publisher_id: "", contributor_id: "", share: "", _destroy: false })
        }
      >
        Add Publisher
      </button>
    </div>
  )
}

export default PublisherForm
