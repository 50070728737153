import React from "react"
import { connect } from "formik"

class ScrollToError extends React.Component {
  componentDidUpdate(prevProps) {
    const { isSubmitting, isValidating } = prevProps.formik

    if (isSubmitting && !isValidating) {
      const element = document.querySelector(".error-msg")

      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        })
      }
    }
  }

  render() {
    return null
  }
}

export default connect(ScrollToError)
