import React from "react"
import CircleX from "../../../../shared/icons/CircleX"
import styles from "../styles/UploadedFile.module.scss"

const UploadedFile = ({ fileName, removeFile, type }) => {
  const fileUploaded = !["", undefined].includes(fileName)

  return (
    <em className={styles.wrapper}>
      <span className={styles.fileName}>{`${fileName}`}</span>
      {fileUploaded && <CircleX handleOnClick={() => removeFile(type)} />}
    </em>
  )
}

UploadedFile.defaultProps = {
  fileName: "",
  type: "",
}

export default UploadedFile
