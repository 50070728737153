import React from "react"
import ReactDOM from "react-dom"
import SongVersionFormContainer from "./admin/pages/song_version_form/SongVersionFormContainer"

const containers = [{ component: SongVersionFormContainer, elementId: "songVersionFormContainer" }]

containers.forEach((container) => {
  const element = document.getElementById(container.elementId)
  if (element === null) return
  const Component = container.component
  const props = JSON.parse(element.dataset.props)
  if (element !== null) {
    ReactDOM.render(<Component {...props} />, element)
  }
})

// Disable LastPass extension for React-Select inputs due to rendering quirks
const elements = document.querySelectorAll("input[id^=react-select]")
elements.forEach((element) => element.setAttribute("data-lpignore", true))
