import React from "react"
import cx from "classnames"
import styles from "../styles/FormFlashMsg.module.scss"

const FormFlashMsg = ({ status, type, children, messages }) => {
  const classNames = cx(
    "flash-msg", // for the scrollIntoView, not styling
    styles.flash,
    styles[status],
    styles[type]
  )

  return (
    <div className={classNames} aria-live="assertive">
      {children}
      {messages && <MessageList messages={messages} />}
    </div>
  )
}

const MessageList = ({ messages }) => {
  return (
    <ul>
      {messages.map((msg, i) => (
        <li key={i}>{msg}</li>
      ))}
    </ul>
  )
}

FormFlashMsg.defaultProps = {
  messages: false,
  type: "admin",
}

export default FormFlashMsg
