import React from "react"
import { Field, ErrorMessage } from "formik"
import cx from "classnames"
import styles from "../styles/CheckboxField.module.scss"
import formStyles from "../styles/form.module.scss"

const CheckboxField = ({ admin, checked, wrapperClass, label, fieldName, disabled }) => {
  return (
    <div className={wrapperClass}>
      <label
        className={cx(styles.label, formStyles.label, {
          [styles.admin]: admin,
        })}
      >
        <Field type="checkbox" name={fieldName} checked={checked} disabled={disabled} />
        <span className={cx(styles.labelText, { [styles.disabled]: disabled })}>{label}</span>
      </label>
      <ErrorMessage
        name={fieldName}
        className={cx(formStyles.errorMsg, "error-msg")}
        component="div"
      />
    </div>
  )
}

CheckboxField.defaultProps = {
  label: "",
  checked: false,
  disabled: false,
}

export default CheckboxField
