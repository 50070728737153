import React from "react"

const CurrentAudioFile = ({ source, filename }) => {
  return (
    <div className="currentAudioFile">
      <strong>File:</strong>
      <a href={source}>{filename}</a>
      <audio controls>
        <source type="audio/mpeg" src={source} />
      </audio>
    </div>
  )
}

export default CurrentAudioFile
