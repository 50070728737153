import React from "react"

const CircleX = ({ handleOnClick }) => {
  return (
    <svg
      onClick={handleOnClick}
      aria-hidden="true"
      focusable="false"
      className="UppyIcon"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd">
        <circle fill="#888" cx="8" cy="8" r="8"></circle>
        <path
          fill="#FFF"
          d="M9.283 8l2.567 2.567-1.283 1.283L8 9.283 5.433 11.85 4.15 10.567 6.717 8 4.15 5.433 5.433 4.15 8 6.717l2.567-2.567 1.283 1.283z"
        ></path>
      </g>
    </svg>
  )
}

CircleX.defaultProps = {
  handleOnClick: () => false,
}

export default CircleX
