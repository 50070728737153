import React from "react"
import axios from "axios"
import cx from "classnames"
import { Field, ErrorMessage } from "formik"
import debounce from "debounce-promise"
import AsyncSelectField from "../../../../shared/components/AsyncSelectField"
import buttonStyles from "../../../../shared/styles/adminButtons.module.scss"
import formStyles from "../../../../shared/styles/form.module.scss"
import styles from "../styles/VendorCreditsForm.module.scss"

const VendorCreditsForm = ({ form, push, remove }) => {
  const searchVendors = async (query) => {
    return await axios
      .get("/api/v1/vendors/autocomplete", { params: { query } })
      .then((response) => response.data)
      .catch((error) => {
        console.log(error)
        return []
      })
  }

  const setValueAndSplit = (fieldName, selectedValue) => {
    form.setFieldValue(fieldName, selectedValue)
    const splitInfo = selectedValue.label.match(/\(split\:\s+([\d\.]+)/)
    const split = splitInfo !== null ? parseFloat(splitInfo[1]) : ""

    if (typeof split === "number") {
      const splitFieldName = fieldName.replace("vendor_id", "split")
      form.setFieldValue(splitFieldName, split)
    }
  }

  return (
    <div>
      {form.values.credits_attributes.length > 0 &&
        form.values.credits_attributes.map((creditAttr, index) => (
          <div key={index} className={styles.form} data-cy={`vendor-credit-form-${index}`}>
            <div className={styles.cornerLabel}>
              {creditAttr.id === "" && (
                <button
                  type="button"
                  className={styles.remove}
                  onClick={() => remove(index)}
                  data-cy={`remove-credit-${index}`}
                >
                  X
                </button>
              )}
            </div>
            <Field
              type="hidden"
              name={`credits_attributes.${index}.id`}
              value={creditAttr.id}
              onChange={form.setFieldValue}
            />
            <AsyncSelectField
              admin={true}
              label="Vendor*"
              fieldName={`credits_attributes.${index}.vendor_id`}
              value={creditAttr.vendor_id}
              loadOptions={debounce(searchVendors, 400)}
              onChange={setValueAndSplit}
              onBlur={form.setFieldTouched}
              placeholder="Search..."
            />
            <div className="field-row" key={index}>
              <label className={cx(formStyles.label, formStyles.admin)}>Split*</label>
              <Field type="number" name={`credits_attributes.${index}.split`} step="0.01" />
              <ErrorMessage
                name={`credits_attributes.${index}.split`}
                className="error-msg"
                component="div"
              />
              {creditAttr.id !== "" && (
                <label style={{ display: "block", marginTop: "20px" }}>
                  <Field
                    name={`credits_attributes.${index}._destroy`}
                    type="checkbox"
                    checked={creditAttr._destroy}
                  />
                  Delete
                </label>
              )}
            </div>
          </div>
        ))}
      <button
        type="button"
        className={buttonStyles.button}
        data-cy="add-vendor-credit"
        onClick={() => push({ id: "", vendor_id: "", split: "", _destroy: false })}
      >
        Add Credit
      </button>
    </div>
  )
}

export default VendorCreditsForm
