import React, { Fragment } from "react"
import { connect, getIn } from "formik"
import cx from "classnames"
import formStyles from "../styles/form.module.scss"

const SelectErrorMessage = ({ formik, className, name }) => {
  const errors = getIn(formik.errors, name)
  const touched = getIn(formik.touched, name)

  return (
    <Fragment>
      {errors && touched && (
        <div className={className}>
          {/* last condition allows for multi-select (array) */}
          {errors.value || errors.label || errors}
        </div>
      )}
    </Fragment>
  )
}

SelectErrorMessage.defaultProps = {
  className: cx(formStyles.errorMsg, "error-msg"),
}

export default connect(SelectErrorMessage)
