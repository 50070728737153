import React from "react"
import cx from "classnames"
import styles from "../styles/Label.module.scss"

const Label = ({ htmlFor, text, hint, classNames }) => {
  return (
    <label htmlFor={htmlFor} className={classNames}>
      <span>{text}</span>
      {hint && <span className={cx(styles.hint, "label-hint")}>{hint}</span>}
    </label>
  )
}

Label.defaultProps = {
  hint: false,
  classNames: styles.label,
}

export default Label
