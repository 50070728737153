import get from "lodash.get"

export const formatAriaLabel = (fieldName) => {
  let array = fieldName.split(/\./g)
  let label = array[array.length - 1]
  return label.replace(/\_/g, " ")
}

export const formatId = (fieldName) => {
  return fieldName.replace(/\./g, "_")
}

export const showErrorBorder = (errors, touched, fieldName) => {
  return get(touched, fieldName) && get(errors, fieldName)
}
