import React from "react"
import cx from "classnames"
import { Field, ErrorMessage } from "formik"
import { formatId, formatAriaLabel } from "../utils/form"
import formStyles from "../styles/form.module.scss"

const TextField = (props) => {
  const {
    wrapperClass,
    inputClass,
    dataCy,
    type,
    label,
    fieldName,
    innerRef,
    disabled,
    placeholder,
    showErrorBorder,
    maxLength,
    admin,
  } = props

  return (
    <div className={cx(formStyles.fieldRow, wrapperClass)} data-cy={dataCy}>
      <label
        htmlFor={formatId(fieldName)}
        className={cx(formStyles.label, { [formStyles.admin]: admin })}
      >
        {label}
      </label>
      <Field
        type={type}
        id={formatId(fieldName)}
        name={fieldName}
        innerRef={innerRef}
        disabled={disabled}
        aria-label={formatAriaLabel(fieldName)}
        placeholder={placeholder}
        maxLength={maxLength}
        className={cx(
          { [formStyles.inputError]: showErrorBorder },
          admin ? formStyles.adminInput : formStyles.input,
          inputClass
        )}
      />
      <ErrorMessage
        name={fieldName}
        // .error-msg is for ScrollToError component, not styling
        className={cx(formStyles.errorMsg, "error-msg")}
        component="div"
      />
    </div>
  )
}

TextField.defaultProps = {
  wrapperClass: "",
  inputClass: "",
  dataCy: "",
  innerRef: null,
  type: "text",
  showErrorBorder: false,
  admin: false,
}

export default TextField
