import React from "react"
import { Formik } from "formik"
import Uppy from "@uppy/core"
import axios from "axios"
import * as yup from "yup"
import SongVersionForm from "./components/SongVersionForm"
import { songVersionParams } from "../../../shared/utils/paramModifiers"

const SongVersionFormContainer = (props) => {
  const { song_version, form_data } = props
  const allLicenseVersionIds = form_data.license_versions.map((l) => l.value)
  const uppy = Uppy({
    autoProceed: true,
    allowMultipleUploads: false,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: [".mp3", ".wav", ".aif", ".aiff"],
    },
    onBeforeFileAdded(file) {
      file.name = file.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    },
  })

  const albumUppy = Uppy({
    autoProceed: true,
    allowMultipleUploads: false,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*"],
    },
  })

  const removeDuplicates = (string) => {
    return [...new Set(string.split(","))].toString()
  }

  const initialValues = (songVersion) => {
    return {
      id: songVersion.id || "",
      cyanite_id: songVersion.cyanite_id || "",
      remote_file_url: "",
      server_filename: songVersion.server_filename || "",
      title: songVersion.title || "",
      composer: songVersion.composer || "",
      publisher: songVersion.publisher || "",
      work_number: songVersion.work_number || "",
      release_year: songVersion.release_year || "",
      artist_id: songVersion.artist_id || "",
      song_id: songVersion.song_id || "",
      duplicate_song_version_id: songVersion.duplicate_song_version_id || "",
      album_id: songVersion.album_id || "",
      remote_art_url: "",
      station_list: songVersion.station_list || [],
      primary_genre_list: songVersion.primary_genre_list || "",
      genre_list: songVersion.genre_list || [],
      cyanite_genre_list: songVersion.cyanite_genre_list,
      mp3_genres: songVersion.mp3_genres || "",
      instrument_list: songVersion.instrument_list || [],
      cyanite_instrument_list: songVersion.cyanite_instrument_list,
      keyword_list: songVersion.keyword_list || [],
      cyanite_keyword_list: songVersion.cyanite_keyword_list
        ? removeDuplicates(songVersion.cyanite_keyword_list)
        : "",
      energy: songVersion.energy || "",
      arc: songVersion.arc || "",
      top_position: songVersion.top_position || false,
      artist_collab: songVersion.artist_collab || false,
      staff_pick: songVersion.staff_pick || false,
      customizable: songVersion.customizable || false,
      is_pub_admin: songVersion.is_pub_admin || false,
      in_track_club: songVersion.in_track_club || false,
      instrumental: songVersion.instrumental || false,
      male_vocals: songVersion.male_vocals || false,
      female_vocals: songVersion.female_vocals || false,
      license_version_ids: songVersion.license_version_ids || allLicenseVersionIds,
      credits_attributes: songVersion.credits_attributes || [
        { id: "", vendor_id: "", split: "", _destroy: false },
      ],
      notes: songVersion.notes || "",
      lyrics: songVersion.lyrics || "",
      state: songVersion.state || { label: "Reviewing", value: "reviewing" },
      song_version_contributors_attributes: songVersion.song_version_contributors_attributes || [],
      song_version_publishers_attributes: songVersion.song_version_publishers_attributes || [],
    }
  }

  const validationSchema = yup
    .object({
      title: yup.string().required("Title is required.").typeError("Title is required"),
      artist_id: yup.object().required("Artist is required."),
      song_id: yup.object().required("Song is required.").typeError("Song is required"),
      primary_genre_list: yup.object().when("state", (state, schema) => {
        return state.value === "reviewing"
          ? schema
          : schema.shape({
              label: yup.string(),
              value: yup.string().required("Primary Genre is required."),
            })
      }),
      energy: yup.object().when("state", (state, schema) => {
        return state.value === "reviewing"
          ? schema
          : schema.shape({
              label: yup.string(),
              value: yup.string().required("Energy is required."),
            })
      }),
      arc: yup.object().when("state", (state, schema) => {
        return state.value === "reviewing"
          ? schema
          : schema.shape({
              label: yup.string(),
              value: yup.string().required("Arc is required."),
            })
      }),
      keyword_list: yup.array().when("state", (state, schema) => {
        return state.value === "reviewing"
          ? schema
          : schema.min(1, "Mood is required.").typeError("Mood is required")
      }),
      state: yup.object().shape({
        label: yup.string(),
        value: yup.string().required("State is required."),
      }),
      credits_attributes: yup.array().when("state", (state, schema) => {
        return state.value === "reviewing"
          ? schema
          : schema.of(
              yup.object().shape({
                id: yup.string().nullable(),
                vendor_id: yup.object().required("Vendor is required."),
                /*ipi_number: yup.string().required("Must have IPI Number"),*/ //If we want to create from the form someday
                split: yup
                  .number()
                  .typeError("Split is required.")
                  .min(0, "Split must be equal to or greater than 0.")
                  .max(100, "Split cannot exceed 100.")
                  .required("Split is required."),
                _destroy: yup.boolean(),
              })
            )
      }),
      song_version_contributors_attributes: yup.array().when("state", (state, schema) => {
        return state.value === "reviewing"
          ? schema
          : schema.of(
              yup.object().shape({
                id: yup.string().nullable(),
                contributor_id: yup.object().required("Contributor is required."),
                /*ipi_number: yup.string().required("Must have IPI Number"),*/ //If we want to create from the form someday
                share: yup
                  .number()
                  .typeError("Split is required.")
                  .min(0, "Split must be equal to or greater than 0.")
                  .max(100, "Split cannot exceed 100.")
                  .required("Split is required."),
                _destroy: yup.boolean(),
              })
            )
      }),
      song_version_publishers_attributes: yup.array().when("state", (state, schema) => {
        return state.value === "reviewing"
          ? schema
          : schema.of(
              yup.object().shape({
                contributor_id: yup.object().required("Publishers need a contributor to represent"),
                id: yup.string().nullable(),
                publisher_id: yup.object().required("Publisher is required."),
                share: yup
                  .number()
                  .typeError("Split is required.")
                  .min(0, "Split must be equal to or greater than 0.")
                  .max(100, "Split cannot exceed 100.")
                  .required("Split is required."),

                _destroy: yup.boolean(),
              })
            )
      }),
    })
    .test(
      "instrumentalOrVocalCheck",
      null,
      ({ instrumental, male_vocals, female_vocals, state }) => {
        if (state.value !== "reviewing" && instrumental === (male_vocals || female_vocals)) {
          return new yup.ValidationError(
            "Instrumental or a vocal is required.",
            null,
            "female_vocals"
          )
        } else {
          return true
        }
      }
    )
    .test("fileUploaded", null, ({ remote_file_url, state }) => {
      const notReviewingOrDeleted = !["reviewing", "deleted"].includes(state.value)
      const newOrDeletedRecord = props.form_data.new_record || props.form_data.deleted_record
      const fileMissing = ["", undefined].includes(remote_file_url)

      if (notReviewingOrDeleted && newOrDeletedRecord && fileMissing) {
        return new yup.ValidationError("Audio file is required.", null, "remote_file_url")
      } else {
        return true
      }
    })
    .test("albumArtUploaded", null, ({ remote_art_url, album_id }) => {
      if (!(remote_art_url || album_id)) return true
      const fileMissing = ["", undefined].includes(remote_art_url)

      if (album_id.__isNew__ && fileMissing) {
        return new yup.ValidationError("Image file is required.", null, "remote_art_url")
      } else {
        return true
      }
    })

  const handleSubmit = (params, actions) => {
    const newRecord = props.form_data.new_record
    const method = newRecord ? "post" : "patch"
    const url = newRecord ? "/admin/song_versions" : `/admin/song_versions/${props.song_version.id}`

    axios
      .request(url, {
        method,
        data: {
          authenticity_token: props.form_data.authenticity_token,
          song_version: songVersionParams(params),
        },
      })
      .then(({ data }) => {
        if (data.success) {
          if (newRecord) {
            actions.resetForm(initialValues({}))
          } else {
            document.querySelector(".sv-title").innerText = data.song_version.title
          }

          uppy.reset()
          actions.setStatus({
            success: true,
            message: data.message,
            portalPath: data.portal_path,
          })
        } else {
          actions.setErrors({ server: data.errors })
          actions.setStatus({ success: false })
        }

        actions.setSubmitting(false)
        document.querySelector(".flash-msg").scrollIntoView({ behavior: "smooth", block: "center" })
      })
      .catch((error) => {
        console.log(error)
        actions.setSubmitting(false)
        actions.setErrors({ server: ["Cannot submit form at the moment. Please try again."] })
        document.querySelector("body").scrollIntoView({ behavior: "smooth", block: "center" })
      })
  }

  return (
    <Formik
      initialValues={initialValues(song_version)}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <SongVersionForm {...formikProps} uppy={uppy} albumUppy={albumUppy} {...props} />
      )}
    </Formik>
  )
}

export default SongVersionFormContainer
