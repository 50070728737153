import isequal from "lodash.isequal"
import clonedeep from "lodash.clonedeep"

// React-Select formats values as { label: "", value: ""}
// so values need to be extracted and modified to work with rails objects
// credits_attributes also need to be formatted as an indexed hash to work with has_many
export const songVersionParams = (params) => {
  const newParams = { ...params }
  const multiSelectAttrs = ["genre_list", "instrument_list", "keyword_list", "station_list"]
  const privateParams = ["duplicate_song_version_id"]
  const singleSelectAttrs = [
    "artist_id",
    "song_id",
    "album_id",
    "primary_genre_list",
    "release_year",
    "energy",
    "arc",
    "state",
  ]

  for (const multiAttr of multiSelectAttrs) {
    const attr = params[multiAttr]
    if (!Array.isArray(attr) || attr.length === 0) continue
    newParams[multiAttr] = attr.map((obj) => obj.value)
  }

  for (const attr of singleSelectAttrs) {
    if (typeof params[attr] !== "object") continue
    newParams[attr] = params[attr].value
  }

  const firstCreditAttr = params.credits_attributes[0]
  const blankCreditAttr = { id: "", vendor_id: "", split: "", _destroy: false }

  if (isequal(firstCreditAttr, blankCreditAttr)) {
    delete newParams["credits_attributes"]
  } else {
    newParams.credits_attributes = params.credits_attributes.reduce((obj, credit, index) => {
      obj[index] = {
        id: credit.id || "",
        vendor_id: credit.vendor_id.value,
        _destroy: credit._destroy,
        split: credit.split,
      }
      return obj
    }, {})
  }

  const firstContributorAttr = params.song_version_contributors_attributes[0]
  const blankContributorAttr = { id: "", contributor_id: "", share: "", _destroy: false }
  if (isequal(firstContributorAttr, blankContributorAttr)) {
    delete newParams["song_version_contributors_attributes"]
  } else {
    newParams.song_version_contributors_attributes =
      params.song_version_contributors_attributes.reduce((obj, contributor, index) => {
        obj[index] = {
          id: contributor.id || "",
          contributor_id: contributor.contributor_id.value,
          _destroy: contributor._destroy,
          share: contributor.share,
        }
        return obj
      }, {})
  }

  const firstPublisherAttr = params.song_version_publishers_attributes[0]
  const blankPublisherAttr = {
    id: "",
    publisher_id: "",
    ipi_number: "",
    share: "",
    contributor_id: "",
  }
  if (isequal(firstPublisherAttr, blankPublisherAttr)) {
    delete newParams["song_version_publishers_attributes"]
  } else {
    newParams.song_version_publishers_attributes = params.song_version_publishers_attributes.reduce(
      (obj, publisher, index) => {
        obj[index] = {
          id: publisher.id || "",
          contributor_id: publisher.contributor_id.value,
          publisher_id: publisher.publisher_id.value,
          _destroy: publisher._destroy,
          share: publisher.share,
        }
        return obj
      },
      {}
    )
  }

  newParams.mp3_genres = [newParams.primary_genre_list, newParams.genre_list].flat().join("/")

  for (const attr of privateParams) {
    delete newParams[attr]
  }

  return newParams
}

export const checkoutParams = (params) => {
  const newParams = { ...params }
  const privateParams = ["agree_to_terms", "save_new_card", "saved_card"]
  const testingParams = ["card_number", "exp_month", "exp_year", "cvc"]
  const singleSelectAttrs = ["country", "subregion"]

  for (const attr of singleSelectAttrs) {
    if (params["billing_information_attributes"]["address_attributes"][attr] === undefined) continue
    newParams["billing_information_attributes"]["address_attributes"][attr] =
      params["billing_information_attributes"]["address_attributes"][attr].value
  }

  for (const attr of privateParams) {
    delete newParams[attr]
  }

  if (window._rails_env !== "test") {
    for (const attr of testingParams) {
      delete newParams[attr]
    }
  }

  return newParams
}

export const profileParams = (params, initialValues) => {
  const newParams = clonedeep(params)

  delete newParams.otherJob

  if (_.isEqual(newParams.profile.address, initialValues.profile.address)) {
    delete newParams.profile.address
  } else {
    newParams.profile.address = _.mapObject(newParams.profile.address, (val) => {
      if (_.isObject(val)) {
        return val.value
      } else {
        return val
      }
    })
  }

  return newParams
}
