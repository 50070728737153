import React from "react"
import { Field } from "formik"
import cx from "classnames"
import styles from "../styles/CheckboxField.module.scss"
import formStyles from "../styles/form.module.scss"

const CheckboxGroupField = (props) => {
  const { admin, name, disabled, formDataArray, values, arrayHelpers, showToggleAll } = props

  const toggleAll = (event) => {
    const { checked } = event.target
    const allOrNothing = checked ? formDataArray.map((f) => f.value) : []
    arrayHelpers.form.setFieldValue(name, allOrNothing)
  }

  return (
    <div className="checkboxGroup">
      {showToggleAll && (
        <label
          className={cx(styles.label, formStyles.label, {
            [styles.admin]: admin,
          })}
        >
          <input
            type="checkbox"
            onChange={(e) => toggleAll(e)}
            checked={arrayHelpers.form.values[name].length === formDataArray.length}
            data-cy="toggle-all"
          />
          <span className={cx(styles.labelText, { [styles.disabled]: disabled })}>toggle all</span>
        </label>
      )}

      {formDataArray.map((f, index) => (
        <label
          key={index}
          className={cx(styles.label, formStyles.label, {
            [styles.admin]: admin,
          })}
        >
          <Field
            type="checkbox"
            name={`${name}.${index}`}
            data-cy={`${name}.${index}`}
            checked={values.includes(f.value)}
            onChange={() => {
              const values = arrayHelpers.form.values[name]

              if (values.includes(f.value)) {
                const indexPosition = values.indexOf(f.value)
                arrayHelpers.remove(indexPosition)
              } else {
                arrayHelpers.push(f.value)
              }
            }}
          />
          <span className={cx(styles.labelText, { [styles.disabled]: disabled })}>{f.label}</span>
        </label>
      ))}
    </div>
  )
}

CheckboxGroupField.defaultProps = {
  admin: false,
  disabled: false,
  showToggleAll: false,
}

export default CheckboxGroupField
