import React, { Component, Fragment } from "react"
import axios from "axios"
import debounce from "debounce-promise"
import AwsS3 from "@uppy/aws-s3"
import { StatusBar } from "@uppy/react"
import { DragDrop } from "@uppy/react"
import { Form, ErrorMessage } from "formik"
import { Field, FieldArray } from "formik"
import { clearUrlQuery } from "../../../../shared/utils/helpers"
import UploadedFile from "./UploadedFile"
import SelectField from "../../../../shared/components/SelectField"
import CreatableSelectField from "../../../../shared/components/CreatableSelectField"
import AsyncSelectField from "../../../../shared/components/AsyncSelectField"
import TextField from "../../../../shared/components/TextField"
import TextAreaField from "../../../../shared/components/TextAreaField"
import CheckboxField from "../../../../shared/components/CheckboxField"
import CheckboxGroupField from "../../../../shared/components/CheckboxGroupField"
import VendorCreditsForm from "./VendorCreditsForm"
import ScrollToError from "../../../../shared/components/ScrollToError"
import CurrentAudioFile from "./CurrentAudioFile"
import FormFlashMsg from "../../../../shared/components/FormFlashMsg"
import buttonStyles from "../../../../shared/styles/adminButtons.module.scss"
import ContributorForm from "./ContributorForm"
import PublisherForm from "./PublisherForm"

class SongVersionForm extends Component {
  state = {
    fileName: "",
    albumfileName: "", // keep "file" lowercase for removeFile function
    showAlbumUpload: false,
    allArtistSongs: [],
    allSongSongVersions: [],
    allArtistAlbums: [],
    selectIsLoading: [],
    artistCollabState: this.props.values.artist_collab || false,
  }

  componentDidMount = () => {
    if (window.location.search.length > 0) clearUrlQuery()

    const presignedPostUrl = "/admin/s3_presigned_post"
    const parentScope = this

    this.props.uppy
      .use(AwsS3, {
        getUploadParameters(file) {
          parentScope.setState({ fileName: file.name })

          return axios
            .get(presignedPostUrl, { params: { bucket: "song-versions" } })
            .then(({ data }) => {
              const { url, fields } = data
              return { method: "post", url, fields }
            })
        },
      })
      .on("upload-success", (file, response) => {
        parentScope.props.setFieldValue("server_filename", file.name)
        parentScope.props.setFieldValue("remote_file_url", response.body.location)
      })
      .on("cancel-all", () => {
        parentScope.setState({ fileName: "" })
      })

    this.props.albumUppy
      .use(AwsS3, {
        getUploadParameters(file) {
          parentScope.setState({ albumfileName: file.name })

          return axios
            .get(presignedPostUrl, { params: { bucket: "album-art" } })
            .then(({ data }) => {
              const { url, fields } = data
              return { method: "post", url, fields }
            })
        },
      })
      .on("upload-success", (_, response) => {
        parentScope.props.setFieldValue("remote_art_url", response.body.location)
      })
      .on("cancel-all", () => {
        parentScope.setState({ albumfileName: "" })
      })
  }

  componentDidUpdate(prevProps) {
    const { album_id, artist_id, song_id } = this.props.values

    if (artist_id !== "" && artist_id !== prevProps.values.artist_id) {
      this.getAllArtistSongs(artist_id)
      this.getAllArtistAlbums(artist_id)
    }
    if (!["", null].includes(song_id) && song_id !== prevProps.values.song_id)
      this.getAllSongSongVersions(song_id)
    if (artist_id !== "" && ["", null].includes(song_id) && song_id !== prevProps.values.song_id)
      this.getAllArtistSongs(artist_id)
    if (artist_id !== "" && ["", null].includes(album_id) && album_id !== prevProps.values.album_id)
      this.getAllArtistAlbums(artist_id)
    if (["", null].includes(song_id) && song_id !== prevProps.values.song_id)
      this.setState({ allSongSongVersions: [], artistCollabState: false })
  }

  componentWillUnmount() {
    this.props.uppy.close()
    this.props.albumUppy.close()
  }

  getAllArtistSongs = (artistId) => {
    this.setState({
      selectIsLoading: [...this.state.selectIsLoading, "song_id"],
    })

    axios.get(`/api/v1/artists/${artistId.value}/songs_autocomplete`).then((response) => {
      const filteredArr = this.state.selectIsLoading.filter((str) => str !== "song_id")

      this.setState({
        allArtistSongs: response.data,
        selectIsLoading: filteredArr,
      })
    })
  }

  getAllArtistAlbums = (artistId) => {
    this.setState({
      selectIsLoading: [...this.state.selectIsLoading, "album_id"],
    })

    axios.get(`/api/v1/artists/${artistId.value}/albums_autocomplete`).then((response) => {
      const filteredArr = this.state.selectIsLoading.filter((str) => str !== "album_id")

      this.setState({
        allArtistAlbums: response.data,
        selectIsLoading: filteredArr,
      })
    })
  }

  getAllSongSongVersions = (songId) => {
    if (songId.__isNew__) return false
    this.setState({
      selectIsLoading: [...this.state.selectIsLoading, "duplicate_song_version_id"],
    })

    axios.get(`/api/v1/songs/${songId.value}/song_versions_autocomplete`).then((response) => {
      const filteredArr = this.state.selectIsLoading.filter(
        (str) => str !== "duplicate_song_version_id"
      )

      this.setState({
        allSongSongVersions: response.data,
        selectIsLoading: filteredArr,
      })
    })
  }
  searchStation = async (query) => {
    return await axios
      .get("/api/v1/stations/autocomplete", { params: { query } })
      .then((response) => response.data)
      .catch((error) => {
        console.log(error)
        return []
      })
  }

  searchArtist = async (query) => {
    return await axios
      .get("/api/v1/artists/autocomplete", { params: { query } })
      .then((response) => response.data)
      .catch((error) => {
        console.log(error)
        return []
      })
  }

  searchVendors = async (query) => {
    return await axios
      .get("/api/v1/vendors/autocomplete", { params: { query } })
      .then((response) => response.data)
      .catch((error) => {
        console.log(error)
        return []
      })
  }

  genresWithoutPrimary = () => {
    const { values, form_data } = this.props
    const primaryGenre = values.primary_genre_list.value
    return form_data.genres.filter((genre) => genre.value !== primaryGenre)
  }

  handleTwoCalls = (field, val) => {
    const { artist_collab } = this.props.values
    if (artist_collab) {
      this.setState({ artistCollabState: artist_collab })
    }
    this.props.setFieldValue(field, val)
  }

  handleDuplicate = (field, val) => {
    const safeValue = val || ""
    this.props.setFieldValue(field, safeValue)
    if (val === null) return false

    axios
      .get(`/api/v1/song_versions/${val.value}/duplicate`)
      .then((response) => {
        // setValues overrides all form values, present or not
        // so these values get merged to prevent that (audio and album file upload)
        const { remote_art_url, remote_file_url, server_filename } = this.props.values
        this.props.setValues({
          ...response.data.song_version,
          remote_art_url,
          remote_file_url,
          server_filename,
        })
      })
      .catch((error) => console.log(error))
  }

  handleAlbumCreate = (value) => {
    this.setState({ showAlbumUpload: true })
    this.props.setFieldValue("album_id", {
      __isNew__: true,
      label: value,
      value,
    })
  }

  removeFile = (fileType) => {
    const album = fileType === "album"
    const uppyInstance = album ? this.props.albumUppy : this.props.uppy
    const remoteUrlKey = album ? "remote_art_url" : "remote_file_url"

    uppyInstance.reset()
    this.setState({ [`${fileType}fileName`]: "" })
    this.props.setFieldValue(remoteUrlKey, "")
    if (!album) this.props.setFieldValue("server_filename", "")
  }

  render() {
    const {
      values,
      setFieldValue,
      setFieldTouched,
      isSubmitting,
      form_data,
      status,
      uppy,
      albumUppy,
      errors,
      admin_user,
    } = this.props
    const { album_id, song_id, state } = values
    const { fileName, albumfileName, showAlbumUpload } = this.state
    const disableArtistCollabField = song_id && typeof values.song_id.value === "number"
    const showCreateAlbumFields = album_id && album_id.__isNew__ && showAlbumUpload
    const showStateField = (state.value === "deleted" && admin_user) || state.value !== "deleted"
    const isInExclusivity = ["partially_exclusive", "archived_exclusive"].includes(state.value)

    return (
      <Form>
        {status && status.success && (
          <FormFlashMsg status="success">
            {status.message} <a href={status.portalPath}>click here to view it in the portal</a>
          </FormFlashMsg>
        )}
        {errors.server && <FormFlashMsg status="error" messages={errors.server} />}
        <ScrollToError />

        <fieldset>
          <legend>File Upload</legend>
          <DragDrop
            uppy={uppy}
            pretty={true}
            height="160px"
            inputName="file"
            note="audio files only (.mp3, .wav, .aif/.aiff)"
            locale={{
              strings: { dropHereOr: "Drop file here or %{browse}" },
            }}
          />
          {!form_data.new_record && (
            <CurrentAudioFile
              source={this.props.song_version.current_file_url}
              filename={this.props.song_version.current_filename}
            />
          )}
          <UploadedFile fileName={fileName} removeFile={this.removeFile} />
          <StatusBar
            uppy={uppy}
            hideAfterFinish={false}
            hideCancelButton={true}
            showProgressDetails
          />
          <ErrorMessage name="remote_file_url" className="error-msg" component="div" />
        </fieldset>

        <fieldset>
          <legend>Basic Information</legend>
          <AsyncSelectField
            admin
            label="artist*"
            fieldName="artist_id"
            value={values.artist_id}
            loadOptions={debounce(this.searchArtist, 400)}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
          />
          <CreatableSelectField
            admin
            label="song*"
            fieldName="song_id"
            placeholder="Select or Create..."
            value={values.song_id}
            isDisabled={values.artist_id === ""}
            isClearable={true}
            isLoading={this.state.selectIsLoading.includes("song_id")}
            options={this.state.allArtistSongs}
            onChange={this.handleTwoCalls}
            onBlur={setFieldTouched}
          />

          <div className="artist-collab-wrapper">
            <CheckboxField
              admin
              label="Song is Artist Collab?"
              fieldName="artist_collab"
              checked={values.artist_collab}
              isDisabled={values.song_id === ""}
              disabled={disableArtistCollabField}
            />
            {/* Only shows the song edit link if a song is already associated */}
            <p>
              {disableArtistCollabField && (
                <Fragment>
                  <i>
                    To change if artist collab, edit parent&nbsp;
                    <a href={`/manage/songs/${values.song_id.value}/edit`} target="_blank">
                      Song
                    </a>
                    . After updating, refresh this edit form to apply the change.
                  </i>
                </Fragment>
              )}
            </p>
          </div>

          <SelectField
            admin
            label="choose song version to duplicate"
            fieldName="duplicate_song_version_id"
            placeholder="Select existing song version..."
            value={values.duplicate_song_version_id}
            isDisabled={["", null].includes(values.song_id)}
            isLoading={this.state.selectIsLoading.includes("duplicate_song_version_id")}
            options={this.state.allSongSongVersions}
            onChange={this.handleDuplicate}
            onBlur={setFieldTouched}
          />
          <Field type="hidden" name="id" value={values.id} />
          <TextField admin label="title*" fieldName="title" />
          <TextField admin label="composer" fieldName="composer" />
          <TextField admin label="publisher" fieldName="publisher" />
          <TextField admin label="work number" fieldName="work_number" />
          <SelectField
            admin
            label="release year"
            fieldName="release_year"
            options={form_data.release_years}
            value={values.release_year}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
          />
          <CreatableSelectField
            admin
            label="Primary Album"
            hint="note: this will replace the song and all its other song version's primary album"
            fieldName="album_id"
            placeholder="Select or Create..."
            value={values.album_id}
            isDisabled={values.artist_id === ""}
            isClearable={true}
            isLoading={this.state.selectIsLoading.includes("album_id")}
            options={this.state.allArtistAlbums}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
            onCreateOption={this.handleAlbumCreate}
          />

          {showCreateAlbumFields && (
            <Fragment>
              <label htmlFor="remote_art_url">Primary Album Art</label>
              <DragDrop
                uppy={albumUppy}
                pretty={true}
                height="160px"
                inputName="remote_art_url"
                note="square image files only (.jpg, .png, .jpeg), 1mb or less"
                locale={{
                  strings: { dropHereOr: "Drop file here or %{browse}" },
                }}
              />
              <UploadedFile fileName={albumfileName} removeFile={this.removeFile} type="album" />
              <StatusBar
                uppy={albumUppy}
                hideAfterFinish={false}
                hideCancelButton={true}
                showProgressDetails
              />
              <ErrorMessage name="remote_art_url" className="error-msg" component="div" />
            </Fragment>
          )}

          <AsyncSelectField
            admin
            label="Stations"
            fieldName="station_list"
            isMulti={true}
            closeMenuOnSelect={false}
            value={values.station_list}
            loadOptions={debounce(this.searchStation, 400)}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
          />
        </fieldset>

        <fieldset>
          <legend>tags</legend>
          <SelectField
            admin
            fieldName="primary_genre_list"
            label="primary genre*"
            options={form_data.genres}
            value={values.primary_genre_list}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
            matchFrom="start"
          />
          <SelectField
            admin
            label="genres"
            fieldName="genre_list"
            isMulti={true}
            closeMenuOnSelect={false}
            options={this.genresWithoutPrimary()}
            value={values.genre_list}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
            matchFrom="start"
          />
          {values.cyanite_id != "" && (
            <div className="cyanite-tags">
              <label>Cyanite genres:</label>
              <span>{values.cyanite_genre_list}</span>
            </div>
          )}
          <SelectField
            admin
            fieldName="instrument_list"
            label="instruments"
            isMulti={true}
            closeMenuOnSelect={false}
            options={form_data.instruments}
            value={values.instrument_list}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
            matchFrom="start"
          />
          {values.cyanite_id != "" && (
            <div className="cyanite-tags">
              <label>Cyanite instruments:</label>
              <span>{values.cyanite_instrument_list}</span>
            </div>
          )}
          <SelectField
            admin
            fieldName="keyword_list"
            label="moods"
            isMulti={true}
            closeMenuOnSelect={false}
            options={form_data.moods}
            value={values.keyword_list}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
            matchFrom="start"
          />
          {values.cyanite_id != "" && (
            <div className="cyanite-tags">
              <label>Cyanite keywords:</label>
              <span>{values.cyanite_keyword_list}</span>
            </div>
          )}
          <SelectField
            admin
            fieldName="energy"
            label="energy*"
            options={form_data.energies}
            value={values.energy}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
            matchFrom="start"
          />
          <SelectField
            admin
            fieldName="arc"
            label="arc*"
            options={form_data.arcs}
            value={values.arc}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
            matchFrom="start"
          />
        </fieldset>

        <fieldset>
          <legend>flags</legend>
          <CheckboxField
            admin
            label="move to top of song versions"
            fieldName="top_position"
            checked={values.top_position}
          />
          <CheckboxField
            admin
            label="staff pick"
            fieldName="staff_pick"
            checked={values.staff_pick}
          />
          <CheckboxField
            admin
            label="customizable"
            fieldName="customizable"
            checked={values.customizable}
          />
          <CheckboxField
            admin
            label="PRO tracking / pub admin"
            fieldName="is_pub_admin"
            checked={values.is_pub_admin}
          />
          <CheckboxField
            admin
            label={
              isInExclusivity ? "in track club (disabled due to exclusivity)" : "in track club"
            }
            fieldName="in_track_club"
            checked={values.in_track_club}
            disabled={isInExclusivity}
          />
          <CheckboxField
            admin
            label="instrumental"
            fieldName="instrumental"
            checked={values.instrumental}
            disabled={values.male_vocals || values.female_vocals}
          />
          <CheckboxField
            admin
            label="male vocals"
            fieldName="male_vocals"
            checked={values.male_vocals}
            disabled={values.instrumental}
          />
          <CheckboxField
            admin
            label="female vocals"
            fieldName="female_vocals"
            checked={values.female_vocals}
            disabled={values.instrumental}
          />
        </fieldset>

        <fieldset>
          <legend>Vendor Credits</legend>
          <FieldArray name="credits_attributes" component={VendorCreditsForm} />
        </fieldset>

        <fieldset>
          <legend>Contributor Information</legend>
          <FieldArray name="song_version_contributors_attributes" component={ContributorForm} />
        </fieldset>

        <fieldset>
          <legend>Publisher Information</legend>
          <FieldArray name="song_version_publishers_attributes" component={PublisherForm} />
        </fieldset>

        <fieldset>
          <legend>licensing</legend>
          <FieldArray name="license_version_ids">
            {(arrayHelpers) => (
              <CheckboxGroupField
                admin
                showToggleAll={true}
                name="license_version_ids"
                arrayHelpers={arrayHelpers}
                values={values.license_version_ids}
                formDataArray={form_data.license_versions}
              />
            )}
          </FieldArray>
        </fieldset>

        <fieldset>
          <legend>other</legend>
          <TextField admin label="cyanite id" fieldName="cyanite_id" disabled={!admin_user} />
          <TextAreaField admin label="notes" fieldName="notes" rows="10" />
          <TextAreaField admin label="lyrics" fieldName="lyrics" rows="10" maxLength="10000" />
          {showStateField && (
            <SelectField
              admin
              fieldName="state"
              label="state*"
              options={form_data.states}
              value={values.state}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              matchFrom="start"
            />
          )}
        </fieldset>

        <button
          className={buttonStyles.black}
          type="submit"
          disabled={isSubmitting}
          data-cy="submit-form"
        >
          {isSubmitting ? "saving" : "save"}
          <span className={isSubmitting ? "spinner" : ""} />
        </button>
      </Form>
    )
  }
}

export default SongVersionForm
