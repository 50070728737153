import React from "react"
import SelectErrorMessage from "./SelectErrorMessage"
import CreatableSelect from "react-select/creatable"
import Label from "./Label"
import cx from "classnames"
import { selectFieldCustomStyle } from "../styles/customStyles"
import formStyles from "../styles/form.module.scss"

const CreatableSelectField = (props) => {
  const {
    fieldName,
    label,
    options,
    onChange,
    onBlur,
    value,
    isMulti,
    closeMenuOnSelect,
    placeholder,
    isDisabled,
    isClearable,
    isLoading,
    allowCreateWhileLoading,
    onCreateOption,
    showErrorBorder,
    hint,
    admin,
  } = props

  const handleChange = (selectedValue) => {
    onChange(fieldName, selectedValue)
  }

  const handleBlur = () => {
    onBlur(fieldName, true)
  }

  return (
    <div className={formStyles.fieldRow}>
      <Label
        htmlFor={fieldName}
        text={label}
        hint={hint}
        classNames={cx(formStyles.label, { [formStyles.admin]: admin })}
      />
      <CreatableSelect
        styles={admin ? {} : selectFieldCustomStyle(showErrorBorder)}
        isMulti={isMulti}
        id={fieldName}
        closeMenuOnSelect={closeMenuOnSelect}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        options={options}
        placeholder={placeholder}
        isDisabled={isDisabled}
        isClearable={isClearable}
        isLoading={isLoading}
        allowCreateWhileLoading={allowCreateWhileLoading}
        onCreateOption={onCreateOption && onCreateOption}
      />
      <SelectErrorMessage name={fieldName} />
    </div>
  )
}

CreatableSelectField.defaultProps = {
  isMulti: false,
  closeMenuOnSelect: true,
  placeholder: "Select..",
  isDisabled: false,
  isClearable: false,
  isLoading: false,
  allowCreateWhileLoading: false,
  hint: false,
  admin: false,
  showErrorBorder: false,
}

export default CreatableSelectField
